<template>
  <div>
    <aside class="container">
      <div class="yearContainer">
        <div class="yearDown">
          <button @click="yearDown">&lt;&lt;</button>
        </div>
        <div class="monthDown">
          <button @click="monthDown">&lt;</button>
        </div>
        <div class="year">
          <h2>
            <span>{{ months[month - 1] }}</span> <span>{{ year }}</span>
          </h2>
        </div>
        <div class="monthUp">
          <button @click="monthUp">&gt;</button>
        </div>
        <div class="yearUp">
          <button @click="yearUp">&gt;&gt;</button>
        </div>
      </div>

      <!-- <div class="months">
        <h2
          v-for="n in 12"
          :key="n"
          class="month"
          :class="{ selected: isSelected(n) }"
          @click="$emit('monthChanged', n)"
        >
          {{ getMonth(n) }}
        </h2>
      </div> -->
    </aside>
  </div>
</template>

<script>
export default {
  name: "MonthSelector",
  props: {
    year: {
      type: Number,
      default: 2020,
    },
    month: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      months: [
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER",
      ], // 0-based, but data is 1-based
    }
  },
  methods: {
    yearUp() {
      this.year += 1
      this.$emit("yearChanged", this.year)
    },
    yearDown() {
      this.year -= 1
      this.$emit("yearChanged", this.year)
    },
    monthUp() {
      if (this.month === 12) {
        this.month = 1
        this.year += 1
        this.$emit("monthChanged", this.month)
        this.$emit("yearChanged", this.year)
      } else {
        this.month += 1
        this.$emit("monthChanged", this.month)
      }
    },
    monthDown() {
      if (this.month === 1) {
        this.month = 12
        this.year -= 1
        this.$emit("yearChanged", this.year)
        this.$emit("monthChanged", this.month)
      } else {
        this.month -= 1
        this.$emit("monthChanged", this.month)
      }
    },
    getMonth(id) {
      return this.months[id - 1]
    },
    isSelected(n) {
      return n === this.month
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  border-bottom: 2px solid var(--orange);

  .yearContainer {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .monthUp {
      display: inline-block;
    }
    .monthDown {
      display: inline-block;
    }
    .yearDown {
      display: inline-block;
    }
    .yearUp {
      display: inline-block;
    }
    .year {
      display: inline-block;
      margin-bottom: 0;
      color: var(--text);
      text-align: center;
    }
    button {
      @extend .pointer;
      @extend .noselect;

      width: 90%;
      height: 90%;

      background-color: none;
      background: none;
      border: none;
      font-size: 2em;
      color: var(--text);
      padding: 0 20px;

      &:hover {
        @include popup(1.02);
      }
    }
  }

  .months {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;

    .month {
      @extend .pointer;
      @extend .noselect;

      padding: 10px;
      margin: 0;
      margin-right: 5px;
      width: calc(70% / 12);
      height: auto;

      font-size: 0.9em;
      background-color: var(--medium);

      border-radius: 10px 10px 0 0;

      &:hover {
        @include popup(1.06);
      }
    }
    .selected {
      background-color: var(--light);
    }

    // Remove right margin from last month
    :last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 650px) {
  .year h2 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 450px) {
  .year h2 {
    font-size: 1em;
  }

  .year h2 span {
    font-size: 1em;
  }

  .container .yearContainer button {
    padding: 0;
    font-size: 1.4em;
  }
}
</style>
