<template>
  <section>
    <table v-if="ready">
      <thead>
        <th v-for="day in marks" :key="day">{{ day }}</th>
      </thead>

      <tr v-for="row in grid">
        <td v-for="day in row" :class="day ? '' : 'dark'">
          <CalendarButton
            v-if="day !== false"
            :currentDay="isCurrentDay(day.day)"
            :day="day.day"
            :events="day.events"
            :selected="selectedDay"
            @selectEvent="selectEvent"
            @selectDay="selectDay"
          />
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import CalendarButton from "@/components/calendar/CalendarButton"

export default {
  name: "CalendarGrid",
  components: { CalendarButton },
  props: {
    days: Array,
    month: Number,
    year: Number,
  },
  data() {
    return {
      marks: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
      ready: true,
      selectedDay: -1,
    }
  },
  methods: {
    isCurrentDay(day) {
      const date = new Date(this.year, this.month - 1, day).getTime();
      const currDate = new Date().setHours(0, 0, 0, 0);
      return date === currDate;
    },
    selectEvent(event) {
      this.$emit("selectEvent", event)
    },
    selectDay(day) {
      this.selectedDay = day
      this.$emit("changeDayEvent", this.selectedDay);
    },
    getEmptyCount() {
      let date = `${this.year}-${this.month}-01` // month must be in format YYYY-MM-DD
      let startDay = new Date(date).getDay()
      startDay = startDay === 0 ? 6 : startDay - 1 // for JS, index 0 is Sunday, I want it to be Monday
      return startDay
    },
  },
  computed: {
    grid() {
      let arr = [] // 3D array representing the calendar, 1st dimension is row, 2nd is days, 3rd are events
      let i = 0 // this.days index
      let index = 0 // arr index
      let beforeEmpty = this.getEmptyCount()
      while (i < this.days.length) {
        arr.push([])

        // Add empty days to first row (from previous months)
        // They are just false values
        if (i === 0) {
          for (let j = 0; j < beforeEmpty; j++) {
            arr[index].push(false)
          }
        }

        // Add days to row
        // Each day in row is an object with a day index and events array
        while (arr[index].length < 7 && i < this.days.length) {
          arr[index].push({ day: i + 1, events: this.days[i] })
          i++
        }

        // Add empty days for the last week
        if (i === this.days.length && arr[index].length % 7 !== 0) {
          for (let j = 0; j < arr[index].length % 7; j++) {
            arr[index].push(false)
          }
        }

        // Next row
        index++
      }
      return arr
    },
  },
  watch: {
    days() {
      this.selectedDay = -1
    },
  },
}
</script>

<style lang="scss" scoped>
section {
  background-color: var(--dark);

  table {
    table-layout: fixed;
    width: 100%;

    color: var(--text);
    background-color: var(--dark);

    thead {
      background-color: var(--medium);

      th {
        padding: 5px;
      }
      :first-child {
        border-radius: 0 0 0 10px;
      }
      :last-child {
        border-radius: 0 0 10px 0;
      }
    }

    tr {
      width: 100%;
      td {
        background-color: var(--background);
        height: 100px;
      }
    }
  }
}
@media screen and (max-width: 440px) {
  td {
    height: 50px !important;
  }
}
</style>
