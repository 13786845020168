<template>
  <div class="edit-event-wrapper" @click.self="$emit('closeModal')">
    <div class="edit-event-container">
      <div class="edit-event-header">
        <h2>Edit Event</h2>
        <img
            class="closeButton"
            @click.self="$emit('closeModal')"
            src="@/assets/icons/x-square.svg"
          />
      </div>
      <div class="edit-event-form">
        <form @submit.prevent="handleSubmit">
          <h4>{{ errorMsg }}</h4>
          <div class="form-controlers-wrapper">
            <div class="form-controler-left">
              <input
                v-model="model.title"
                type="text"
                placeholder="Event title"
                min="3"
                max="30"
                class="event-input"
              />
              <textarea
                v-model="model.description"
                rows="5"
                max="250"
                placeholder="Event description"
              ></textarea>
              <input v-model="model.date_when" type="hidden" />
              <b-time v-model="model.date_time"></b-time>
            </div>
            <div>
              <b-calendar
                v-model="model.date_datum"
                @change="date_datum"
              ></b-calendar>
            </div>
          </div>
          <input type="submit" value="Update event" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import { BCalendar, BTime, BIconChevronDown } from "bootstrap-vue";

export default {
  name: "EditEvent",
  components: { BCalendar, BTime, BIconChevronDown },
  directives: {
    "b-calendar": BCalendar,
    "b-time": BTime,
  },
  props: {
    eventD: {
      type: Object,
    },
  },
  data() {
    return {
      model: {
        title: "",
        description: "",
        date_when: "",
        date_datum: "",
        date_time: "",
      },
      errorMsg: "",
    };
  },
  async mounted() {
    if (this.$route.query.eventId !== undefined) {
      const res = await this.$api.getSingleEvent(this.$route.query.eventId);
      if (res.success) {
        this.model.title = this.eventD.title;
        this.model.description = res.event[0].description;
        this.model.date_datum = res.event[0].date_when.split(" ")[0];
        this.model.date_time = res.event[0].date_when.split(" ")[1];
      }
    } else {
      this.model.title = this.eventD.title;
      this.model.description = this.eventD.description;
      this.model.date_datum = this.eventD.date_when.split(" ")[0];
      this.model.date_time = this.eventD.date_when.split(" ")[1];
    }
  },
  methods: {
    async handleSubmit() {
      const newEvent = {};
      if (this.model.title !== "" && this.model.title !== undefined) {
        newEvent.title = this.model.title;

        if (this.model.description.length > 255) {
          this.errorMsg = "Description can't be more than 255 character";
        } else {
          newEvent.description = this.model.description;

          if (this.model.date_time === "" || this.model.date_datum === "") {
            this.errorMsg = "Pleas select time and date of a event";
          } else {
            newEvent.date_when = `${this.model.date_datum} ${this.model.date_time}`;

            const res = await this.$api.editEvent(this.eventD.id, newEvent, this.userStore.accessToken);
            if (res.success) {
              this.$emit("editFormClosed", newEvent);
            } else {
              this.errorMsg = res.errMsg;
            }
          }
        }
      } else {
        this.errorMsg = "Title of a event can't be empty";
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
    date_datum() {
      this.model.date_when = this.model.date_datum + " " + this.model.date_time;
      return this.model.date_when;
    },
  },
};
</script>

<style scoped lang="scss">
.edit-event-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .edit-event-container {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 999;
    padding: 20px;
    background-color: #18191f;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    @include respond-to("small") {
      width: 250px;
    }
    .edit-event-header {
      color: #fff;
      text-align: center;

    }

    .closeButton {
      position: absolute;
      right: 10px;
      top: 10px;
      @extend .pointer;
      @extend .nodrag;
      @extend .noselect;
      @include white-filter;

      height: 35px;
      padding: 5px;

      &:hover {
        @include popup(1.05);
      }
    }

    .edit-event-form {
      form {
        h4 {
          color: #fd6631;
          text-align: center;
        }

        input,
        textarea {
          @include text-input;
          @include center;

          display: block;
          box-sizing: content-box;
          width: 100%;
          max-width: 300px;
          padding: 10px;
          margin-bottom: 10px;

        }
        input[type="submit"] {
          @include button($gradient: true);
          @include center;
          margin-top: 15px;
          width: 60%;
        }

        .form-controlers-wrapper {
          display: flex;
          flex-direction: row;

          @include respond-to("medium") {
            flex-direction: column;
          }
          .form-controler-left {
            padding-right: 20px;
          }
        }

        .b-time::v-deep {
          background-color: var(--medium);
          border-radius: 15px;
          overflow: hidden;
          padding: 5px;
          width: 310px !important;
          color: #fff;
          margin-top: 10px;
          @include respond-to("medium") {
            width: 100% !important;
          }
          .b-time-header {
            box-shadow: 0 0 0 1px var(--orange) inset;
            padding: 10px;
            border-radius: 15px;
          }
          [role="group"] {
            display: flex;
            flex-direction: row;
            text-align: center;
            align-items: center;
            align-content: center;
            .b-form-spinbutton {
              /*display: inline-block;*/
              display: flex;
              flex-direction: column;
              align-items: center;
              align-content: center;
              .btn {
                @include button($padding: 5px, $color: var(--medium-dark));
                display: block;
              }
              output {
                display: block;
              }
            }
            .d-flex {
              display: flex;
              flex-direction: column;
              align-items: center;
              align-content: center;
              svg {
                flex: 0.8 0.8;
              }
            }
          }
        }

        .b-calendar::v-deep {
          margin: 0;
          padding: 0;
          .b-calendar-inner {
            color: #fff;
            background-color: var(--medium);
            border-radius: 15px;
            overflow: hidden;
            padding: 5px;
            @include respond-to("small") {
              width: 230px !important;
            }
          }
          .b-calendar-header {
            box-shadow: 0 0 0 1px var(--orange) inset;
            padding: 10px;
            border-radius: 15px;
          }
          .b-calendar-nav {
            display: flex;
            flex-direction: row;
            text-align: center;
            button {
              flex: 1 1 0px;
              line-height: 1em;
              @include button($padding: 5px, $color: var(--dark-light));
            }
          }
          .b-calendar-grid-weekdays {
            display: flex;
            flex-direction: row;
            text-align: center;
            .col {
              flex: 1 1 0px;
            }
          }
          .b-calendar-grid-body {
            .row {
              display: flex;
              flex-direction: row;
              .col {
                /*background: silver;
              padding: 3px;*/
                flex: 1 1 0px;
                text-align: center;
                padding: 4px;
                margin: 0;
                /*outline: 1px solid red;*/

                .btn {
                  @include button($padding: 5px, $color: var(--medium-dark));
                  width: 100%;
                  margin: 0;
                  padding: 4px 0;
                  display: block;
                  &.text-muted {
                    opacity: 0.4;
                  }
                  &.focus {
                    box-shadow: 0 0 3px var(--orange-light);
                  }
                  &.active {
                    @include gradient();
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
