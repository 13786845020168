<template>
  <div v-if="show" class="hiddenBlock" @click.self="deSelectEvent">
    <div
      v-if="event.id !== undefined"
      class="container"
      :class="{ modal: show, hidden: !show }"
    >
      <div class="info">
        <div class="group">
          <h4>Hosted by</h4>
          <h3>{{ SetHostName }}</h3>
        </div>
        <div class="info-btn">
          <img
            v-if="show && (userStore.user.isAdmin || userStore.user.id === event.id_creator)"
            @click="deleteMode = true"
            class="editButton"
            src="@/assets/icons/trash-2.svg"
          />
          <img
            v-if="show && (userStore.user.isAdmin || userStore.user.id === event.id_creator)"
            @click="editEvent()"
            class="editButton"
            src="@/assets/icons/edit-2.svg"
          />
          <img
            v-if="show"
            @click="deSelectEvent"
            class="closeButton"
            src="@/assets/icons/x-square.svg"
          />
        </div>
      </div>
      <div v-if="event.event_tags !== null">
        <div v-for="eTag in eventTags" :key="eTag" class="tag">
          <router-link :to="'/tag/' + eTag">{{ eTag }}</router-link>
        </div>
      </div>

      <div class="content">
        <h1>{{ event.title }}</h1>
        <p
          v-html="event.description"
          v-linkified:options="{ className: 'stylingLink' }"
        ></p>
        <div class="date">
          <div>
            <img src="@/assets/icons/calendar.svg" />
            <h2>{{ $formatter.formatDateTime(event.date_when, true) }}</h2>
          </div>
          <div class="type">
            <img src="@/assets/icons/info.svg" />
            <h3>Group event</h3>
          </div>
        </div>
      </div>
    </div>
    <edit-event
      v-if="editMode"
      :eventD="event"
      @editFormClosed="eventEdited"
      @closeModal="editMode = false"
    ></edit-event>
    <delete-event v-if="deleteMode" @deletion="deletionAnswer"></delete-event>
  </div>
</template>

<script>
// Stores
import { useUserStore } from "@/stores/UserStore";
import { mapStores } from "pinia";
// Components
import linkify from "vue-linkify";
import TagButton from "../tag/TagButton.vue";
import EditEvent from "./EditEvent.vue";
import DeleteEvent from "./DeleteEvent.vue";

export default {
  name: "EventDetails",
  components: { TagButton, EditEvent, DeleteEvent },
  props: {
    event: Object,
    tags: Array,
    selectedDay: Number,
  },
  data() {
    return {
      editMode: false,
      deleteMode: false,
      show: false,
      eventTags: [],
      timer: null
    };
  },
  watch: {
    event: function() {
      this.show = this.event.id !== undefined;
    },
    show() {
      if (
        this.event.event_tags !== null &&
        this.event.event_tags !== undefined
      ) {
        const event_tags_id = this.event.event_tags.split(",");
        for (const tag of this.tags) {
          for (let eTag of event_tags_id) {
            if (+eTag === tag.id_tag) {
              this.eventTags.push(tag.name);
            }
          }
        }
      } else {
        this.eventTags = [];
      }
    },
  },
  mounted() {
    if((this.$route.query.editMode === 'true')){
      this.timer = setTimeout(() => {
        if(this.event.id_creator === this.userStore.user.id || this.userStore.isAdmin){
          this.editMode = true;
        }
      }, 500)
    }
  },
  unmounted() {
    clearTimeout(this.timer);
  },
  computed: {
    ...mapStores(useUserStore),
    SetHostName() {
      return this.event.name_group;
    },
  },
  methods: {
    editEvent() {
      this.editMode = true;
    },
    async deletionAnswer(answer) {
      if(answer === 'Yes'){
        await this.deleteEvent();
      } 
      this.deleteMode = false;
    },
    eventEdited(value) {
      this.$emit("getNewEvent", value);
      this.editMode = false;
      this.show = false;
      this.$router.push("/calendar");
    },
    async deleteEvent() {
      if (this.$route.query.eventId !== undefined) {
        const res = await this.$api.deleteEvent(this.$route.query.eventId, this.userStore.accessToken);
        if (res.success) {
          this.show = false;
          this.$emit("eventDeleted");
          this.$router.push("/calendar");
        }
      } else {
        const res = await this.$api.deleteEvent(this.event.id, this.userStore.accessToken);
        if (res.success) {
          this.show = false;
          this.$emit("eventDeleted");
        }
      }
    },
    deSelectEvent() {
      let event = {};
      this.$emit("selectEvent", event);
      if (this.$route.query.eventId !== undefined) {
        this.$router.replace({ query: "" });
      }
    },
  },
  directives: {
    linkified: linkify,
  },
};
</script>

<style lang="scss" scoped>
.hiddenBlock {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: transparent;
}

.modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 60% !important;
  max-height: 60%;

  overflow: auto;
  @include scrollbars();
}

.hidden {
  display: none;
}

.container {
  @include side-component;

  padding: 20px;

  word-break: break-all;
  color: var(--text);

  .info {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .group {
      h4 {
        display: inline;
        margin-right: 1em;
        font-size: 0.8em;
        font-weight: normal;
      }
      h3 {
        display: inline;
        font-size: 1em;
        font-weight: normal;
      }
    }
  }
  .content {
    .date {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div {
        display: flex;
        align-items: center;
      }

      img {
        height: 2em;
      }
      h2 {
        margin-left: 10px;
        font-size: 1.1em;
        font-weight: normal;
      }
    }
  }
  .type {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 140px;

    img {
      height: 1.5em;
      width: 1.5em;
    }
    h3 {
      font-size: 0.9em;
      font-weight: normal;
      margin-left: 5px;
    }
  }
}

.tag {
  @extend .noselect;
  display: inline-block;
  padding: 8px;
  border: none;
  border-radius: 15px;
  margin: 1px;

  font-size: 0.8em;

  background-color: var(--medium);

  a {
    text-decoration: none;
    color: var(--light);
  }
}

.closeButton {
  @extend .pointer;
  @extend .nodrag;
  @extend .noselect;
  @include white-filter;

  height: 35px;
  padding: 5px;

  &:hover {
    @include popup(1.05);
  }
}

.editButton {
  @extend .pointer;
  @extend .nodrag;
  @extend .noselect;
  @include white-filter;

  height: 15px;
  // margin: 5px;
  margin-left: 10px;
  padding: 5px;

  border: 3px solid #fff;
  border-radius: 5px;

  &:hover {
    @include popup(1.05);
  }
}

.type img {
  filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%)
    contrast(102%);
}

@media screen and (max-width: 700px) {
  .container .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .container .info .type {
    justify-content: flex-start;
  }

  .container .info .group h4,
  .container .info .group h3 {
    display: block;
  }

  .content {
    h1 {
      font-size: 2em;
    }
  }
}

@media screen and (max-width: 450px) {
  .content {
    h1 {
      font-size: 1.5em;
    }
  }
  .modal {
    width: 80% !important;
  }
}
</style>
