<template>
  <div class="container" @click="selectDay" :class="{ selected: isSelected, 'currentDay': currentDay }">
    <h1>{{ day }}</h1>

    <div class="preview" v-if="!isSelected">
      <div class="event" v-for="(ev) in top">
        <img src="@/assets/icons/calendar.svg" />
        <h1>{{ ev.title }}</h1>
      </div>
      <div v-if="events.length > 2" class="preview__more">
        <span class="preview__more--text">
          + {{ events.length - 2 }} more events
        </span>
      </div>
    </div>

    <div class="box" :class="{ hide: !isSelected }">
      <h4 v-if="events.length < 1">No events this day.</h4>

      <div
        class="event"
        v-for="(ev, index) in events"
        @click.stop="selectEvent(ev)"
      >
        <img src="@/assets/icons/calendar.svg" />
        <h1>{{ ev.title }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarButton",
  props: {
    day: Number,
    events: Array,
    selected: Number,
    currentDay: Boolean
  },
  methods: {
    selectDay() {
      this.$emit("selectDay", this.selected === this.day ? -1 : this.day)
    },
    selectEvent(event) {
      this.$emit("selectEvent", event);
    },
  },
  computed: {
    count() {
      return this.events.length < 10 ? this.events.length : "9+"
    },
    isSelected() {
      return this.selected === this.day
    },
    top() {
      return this.events.slice(0, 2)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  @extend .noselect;
  @extend .pointer;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;

  .preview {
    &__more {
      padding: 0 5px;
      
      &--text {
        @include dot-overflow;
        color: var(--text);
        font-weight: 700;
      }
    }
  }

  &.selected,
  &.currentDay {
    @include gradient;
  }

  &:hover {
    @include gradient;
  }

  .event {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0 5px;

    img {
      width: 15px;
      height: 15px;
    }
    h1 {
      @include dot-overflow;
    }
  }
  h1 {
    // Day number
    margin: 3px;
    font-size: 1em;
  }
  .box {
    position: absolute;
    z-index: 10;
    left: -30px;
    right: -30px;
    margin: 0 auto;

    max-height: 120%;
    overflow: auto;

    @include gradient;

    @include scrollbars($radius: 5px);

    border-radius: 5px;
    padding-bottom: 5px;
    @include box-shadow;

    .event:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    h4 {
      // "No events this day." text
      color: var(--text);
      text-align: center;
    }
  }
}
.hide {
  display: none;
}
</style>
