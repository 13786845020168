<template>
    <div class="delete-event-container">
        <h2>Event Deletion</h2>
        <h3>Are you sure you want to delete this event?</h3>
        <div class="delete-event-btn">
            <button type="button" @click="confirmation = true; $emit('deletion', 'Yes')">Yes</button>
            <button type="button" @click="confirmation = false; $emit('deletion', 'No')">No</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeleteEvent',
    confirmation: ''
}
</script>

<style scoped lang="scss">
.delete-event-container {
    position: fixed;
    background: #44464c;
    color: var(--light);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    padding: 15px;
    border-radius: 10px;

    h2, h3 {
        text-align: center;
    }

    .delete-event-btn {
        display: flex;
        justify-content: center;

        button {
            cursor: pointer;
            @extend .pointer;
            @extend .noselect;
            @extend .nodrag;

            &:hover {
                @include popup(1.05);
            }

            @include gradient;

            width: 30%;
            margin: 0;
            @include center;
            padding: 10px;

            border: none;
            border-radius: 10px;
            font-size: 1.1em;
            color: var(--light);
        }
    }
}
</style>